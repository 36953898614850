import React, { Component } from "react"
import { withStyles } from "@material-ui/core"
import CardHeader from "@material-ui/core/CardHeader"
import MaterialCard from "@material-ui/core/Card"
import red from "@material-ui/core/colors/red"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import dayjs from "dayjs"
import Img from "gatsby-image"

export interface Article {
  id: number
  title: string
  slug: string
  content: string
  lead: string
  renditions: Array<{
    url: string
    href: string
  }>
  article_path: string
  published_articles: Array<{
    url: string
  }>
  author: {
    name: string
    image: string
    biography: string
    imageFile: any
  }
  published_at: string
  category: {
    title: string
    slug: string
  }
  categoryImage: any
  articleImage: any
}

interface Props {
  classes: any
  article: Article
}

const styles = () => ({
  card: {
    maxWidth: 400,
    marginBottom: 20,
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  content: {
    padding: "0 16px",
  },
  title: {
    fontSize: "11px",
  },
})

class ArticleCard extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <Link
        href={this.props.article.article_path}
        style={{ textDecoration: "none", width: "95%" }}
      >
        <MaterialCard className={classes.card}>
          {this.props.article.categoryImage && (
            <Img
              fluid={this.props.article.categoryImage.childImageSharp.fluid}
            />
          )}
          <CardHeader
            title={this.props.article.title}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: "1.3em",
              height: "5.3em",
              display: "block",
            }}
          />
          <CardHeader
            avatar={
              <Img
                style={{ width: 50, height: 50, borderRadius: "50px" }}
                fluid={
                  this.props.article.author.imageFile.childImageSharp.fluid
                }
              />
            }
            title={this.props.article.author.name}
            subheader={
              <Typography component="p">
                <Link href={this.props.article.article_path}>
                  {this.props.article.category.title}
                </Link>
              </Typography>
            }
          />
        </MaterialCard>
      </Link>
    )
  }
}
export default withStyles(styles)(ArticleCard)
