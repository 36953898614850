import React from "react"
import styled from "styled-components"

export const CardColumns = styled.div`
  display: grid;
  justify-items: center;
  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;